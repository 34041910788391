// react libraries import
import React from 'react';

// custom components import
import Header from "../components/navigation/header/Header";
import Footer from "../components/navigation/footer/Footer";

const Layout = ({ children }) => (
    <>
      <Header />
      {children}
      <Footer />
    </>
);

export default Layout;