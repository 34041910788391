// react librarries import
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

//custom libraries/componets import
import Navlinks from '../Navlinks'; // list of all navlinks

const Footer = () => {
    
    return(

        // Footer Component
        <div className="w-full px-4 py-4 bg-fourth">
            <div className="flex flex-wrap -mx-3 overflow-hidden sm:-mx-3 md:-mx-3">

                {/** logo and copyright */}
                <div className="my-2 px-2 w-full overflow-hidden md:w-1/2 lg:w-1/3 xl:w-1/3">
                    <div className="flex flex-col items-center">
                        <StaticImage
                            src="../../../images/logo.png"
                            width={100}
                            quality={100}
                            className="my-4 w-14 md:w-18"
                            formats={["auto", "webp", "avif"]}
                            alt="School Logo"
                        />
                        <div className="flex md:flex-col item-center text-sm font-semibold tracking-tight ml-4 text-primary">
                        <span> SHREE MAHENDRA &nbsp;</span> <span> SECONDARY SCHOOL</span>
                        </div>
                        <div className="text-center text-xs my-4 text-primary">
                            Copyright © 2021
                            <div className="mt-3"> Shree Mahendra Secondary School </div>
                            <div className="mt-3">All rights reserved</div>
                        </div>
                        <div className="flex items-center">
                            <Link 
                                to="https://www.facebook.com/Mahendra-Secondary-School-Machhapuchchhre-4-Lahachok-Kaski-108127220889662">
                                <StaticImage
                                    src="../../../images/facebook.png"
                                    width={100}
                                    quality={100}
                                    className="my-4 w-8 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-x-105 cursor-pointer"
                                    formats={["auto", "webp", "avif"]}
                                    alt="Facebook"
                                />
                            </Link>
                        </div>
                    </div>
                </div>

                {/** School */}
                <div className="my-2 px-2 w-full overflow-hidden md:w-1/2 lg:w-1/6 xl:w-1/6">
                    <div className="text-center md:text-left text-lg text-primary font-bold mt-8 mb-7 underline">School</div>
                    <ul class="list-inside bg-rose-200">
                        {Navlinks.map((link, index) => {
                            return (
                                <li key={index} >
                                    <Link  
                                        to={link.link}
                                        className={`my-3 mx-auto md:mx-0 w-max text-primary cursor-pointer block text-base font-medium transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-x-105`}
                                    >
                                            {link.label}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                {/** Support */}
                <div className="my-2 px-2 w-full overflow-hidden md:w-1/2 lg:w-1/5 xl:w-1/5">
                    <div className="text-center md:text-left text-lg text-primary font-bold mt-8 mb-7 underline">Contact</div>
                    <div className="flex item-center justify-center md:justify-start">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-5 w-5" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="white"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <span className="text-sm text-primary ml-2">Machhapuchchhre-4, Lahachok, Kaski</span>
                    </div>
                    <div className="flex item-center mt-4 justify-center md:justify-start">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-5 w-5" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="white"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>
                        <span className="text-sm text-primary ml-2">061-621313</span>
                    </div>
                    <div className="flex item-center mt-4 justify-center md:justify-start">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-5 w-5" 
                            fill="none" 
                            viewBox="0 0 24 24" stroke="white"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                        </svg>
                        <span className="text-sm text-primary ml-2">shreemahendra.mabi@gmail.com</span>
                    </div>
                </div>

                {/** School Map */}
                <div className="my-2 px-0 md:px-2 w-full overflow-hidden md:w-1/2 lg:w-1/4 xl:w-1/4">
                    <div className="text-center md:text-left text-lg text-primary font-bold mt-8 mb-7 underline">Location</div>
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3512.8608610413376!2d83.92821371445838!3d28.30253870569145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3995ecd51be6d9e9%3A0xdac037dc6330b801!2sShree%20Mahendra%20Secondary%20School%2C%20Lahachowk!5e0!3m2!1sen!2snp!4v1631874858795!5m2!1sen!2snp" 
                        width="400"
                        height="300" 
                        style={{ border: 0 }}
                        title="School Map"
                        allowfullscreen=""
                        className="mx-auto md:mx-0 rounded-md" 
                        loading="lazy"
                    >
                    </iframe>
                </div>
            </div>
            <div className="flex items-center justify-center text-xs my-4 text-white">
                Powered by
                <Link  
                    to='https://twoacesolutions.com.np'
                    className={`ml-2 text-blue hover:underline cursor-pointer block text-sm font-semibold`}
                >
                        Two Ace Solutions Pvt Ltd
                </Link>
            </div>
        </div>
    )
}

export default Footer;