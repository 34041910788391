

const Navlinks = [
    {
        label: 'About us',
        link: '/about-us',
        isSelected: false,
        icon: '',
    },
    {
        label: 'Message from Principle',
        link: '/message-from-principle',
        isSelected: false,
        icon: '',
    },
    {
        label: 'Facilities',
        link: '/facilities',
        isSelected: false,
        icon: '',
        dropdown: [],
    },
    {
        label: 'Notices',
        link: '/notice-and-events',
        isSelected: false,
        icon: '',
    },
    {
        label: 'Gallery',
        link: '/gallery',
        isSelected: false,
        icon: '',
    },
    {
        label: 'Contact us',
        link: '/contact-us',
        isSelected: false,
        icon: '',
    },
];

export default Navlinks;