//react libraries import
import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';


// custom libraries import
import Navlinks from '../Navlinks'; // list of all navlinks


// This component is the navigation header of the site.
// The styling is handled using tailwind css


const Header = () => {


    const [ showMobileMenu, setShowMobileMenu ] = useState(false);

    useEffect(() => {

        const button = document.querySelector('#menu-button');
        const menu = document.querySelector('#mobile-menu');


        button.addEventListener('click', () => {
            menu.classList.toggle('hidden');
            setShowMobileMenu(oldState => !oldState);
        });


        return () => button.removeEventListener('click', null);

    },[]);

    const handleMenuClick = () => {
        document.querySelector('#mobile-menu').classList.add('hidden');
        setShowMobileMenu(false);
    }
    

    return (
        <div >
            {/** Top Navbar */}
            <div className={`hidden md:flex w-full justify-between items-center h-10 bg-secondary dark:bg-gray-300 shadow py-2 px-16`}>
                <div className="flex items-center">
                    <svg className="text-sm font-semibold h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#556e86">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span className="font-normal text-sm tracking-wide ml-2 text-blue font-semibold">Sun - Fri : 8 AM - 5 PM</span>
                </div>
                <div className={`flex items-center`}>
                    <div className="flex items-center mx-4 my-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#556e86">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>
                        <span className="font-normal text-sm tracking-wide ml-2 text-blue font-semibold">061-621313</span>
                    </div>
                    <div className="flex items-center mx-4 my-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#556e86">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                        </svg>
                        <span className="font-normal text-sm tracking-wide ml-2 text-blue font-semibold">shreemahindra.mabi@gmail.com</span>
                    </div>
                </div>
            </div>


            {/** Bottom Navbar */}
            <nav
                className={`bg-primary dark:bg-gray-800 shadow pt-2 md:py-4`}
            >
                <div className="max-w-full px-4 md:px-8">
                    <div className="flex items-center justify-between h-20">
                        <div className={`w-full justify-between flex items-center`}>
                            <div className="flex items-center flex-shrink-0 text-white mr-6">
                                <Link 
                                    to='/'
                                    className="flex items-center flex-shrink-0"
                                >
                                    <StaticImage
                                        src="../../../images/logo.png"
                                        width={100}
                                        quality={100}
                                        className="my-4 w-14 md:w-20"
                                        formats={["auto", "webp", "avif"]}
                                        alt="School Logo"
                                    />
                                    <span className="text-sm md:text-sm xl:text-base text-third font-semibold tracking-tight ml-4 text-secondary ">
                                        SHREE MAHENDRA<br></br>
                                        SECONDARY SCHOOL
                                    </span>
                                </Link>
                            </div>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                id="menu-button"
                                className="h-8 w-8 cursor-pointer rounded md:hidden block border border-white p-1" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                stroke="white"
                            >
                                {
                                    showMobileMenu ?
                                    <path 
                                            strokeLinecap="round" 
                                            strokeLinejoin="round" 
                                            strokeWidth={2} 
                                            d="M6 18L18 6M6 6l12 12" 
                                    />
                                        :
                                    <path 
                                        strokeLinecap="round" 
                                        strokeLinejoin="round" 
                                        strokeWidth={2} 
                                        d="M4 6h16M4 12h16M4 18h16" 
                                    />
                                    
                                }
                            </svg>
                            
                            <div className="hidden w-full md:flex md:items-center md:w-auto">
                                <ul
                                    className="
                                    pt-4
                                    text-base text-gray-700
                                    md:flex
                                    md:justify-between 
                                    md:pt-0"
                                >
                                    {Navlinks.map((link) => {
                                        return (
                                            <li key={link.label} >
                                                <Link  
                                                    to={link.link || '#'}
                                                    className={`${
                                                        link.isSelected
                                                            ? 'text-gray-300 dark:text-white'
                                                            : 'text-third'
                                                    } hover:text-gray-300 dark:hover:text-white md:p-4 py-2 block rounded-md text-sm md:text-sm xl:text-base font-medium ${
                                                        ' transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110' }`}
                                                >
                                                        {link.label}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                {/** Mobile Navigation Menu */}
                <div id="mobile-menu" className="hidden absolute top-37 w-full flex items-center py-3 px-6 bg-white transition-all duration-600 ease-in z-50">
                    <ul
                        className="
                        text-base 
                        text-gray-700
                        w-full
                        pt-0"
                    >
                        {Navlinks.map((link) => {
                            return (
                                <li key={link.label} >
                                    <Link  
                                        to={link.link || '#'}
                                        onClick={handleMenuClick}
                                        className={`text-third px-2 py-2 block text-base font-medium `}
                                    >
                                            {link.label}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div> 


            </nav>
        </div>
    );
}

export default Header;